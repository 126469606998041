import { Moment } from "moment";
import moment = require("moment");

import { ShowInWorkbench } from "./showInWorkbench";
import {
  AttributeGroup,
  Image,
  AttributeGroupTO,
  Attribute,
  ValueEdge,
  IProduct,
  ProductTO,
  EdgeWithImage,
  ValuesTO,
  ApprovalStates,
  sort,
  removeEmptyChildrenAndAttributes,
  setChildGroups,
  CreateAttributeGroupProps,
  AttrValueGroups
} from "./types";
import { AttributeValueBase } from "../ProductEditor/formStore";
import { batchAttributeGroupRequest } from "../ProductViewer/client/client";
import { observable, runInAction, reaction, action } from "mobx";
import { ProductForEditor } from "./productForEditor";
import { ProductForEditorRequest } from "../ProductEditor/client/client";

export class Product implements IProduct {
  stepId: string;
  title: string;
  path: string;
  topAttributeGroup: AttributeGroup | undefined;
  attributeGroups: AttributeGroup[];
  primaryImage: Image | undefined;
  productImages: Image[];
  showInWorkbench = new ShowInWorkbench();
  currentRevision: string;
  approvalState: ApprovalStates;
  lastUpdateDate?: Moment;
  nodeType: "product";
  attributeGroupMap: Map<string, AttributeGroupTO | undefined>;
  productForEditorShadow: ProductForEditor;
  lazyLoadTimer: number = 200;
  updateCounter: number = 0;
  @observable isProductForEditorReady = false;
  @observable productForEditor: ProductForEditor;

  constructor(private productTO: ProductTO, private batchAttributeGroup: batchAttributeGroupRequest, private fetchProductForEditor: ProductForEditorRequest, isEditable: boolean) {
    const { stepId, title, currentRevision } = productTO;
    this.stepId = stepId;
    this.title = title;
    this.currentRevision = currentRevision;
    this.lastUpdateDate = productTO.lastUpdateDate ? moment(productTO.lastUpdateDate) : undefined;
    isEditable && setTimeout(() => {
      this.lazyLoadProductForEditor();
    }, this.lazyLoadTimer);
    this.initialize();
  }


  lazyLoadProductForEditor = () => {
    if (this.isProcessing === false) {
      this.fetchProductForEditor().then((response) => {
        this.productForEditorShadow = new ProductForEditor(response.data.product, this.batchAttributeGroup, this.attributeGroupMap, this.onProductForEditorIsReady);
      });

    } else {
      setTimeout(() => {
        this.lazyLoadProductForEditor();
      }, this.lazyLoadTimer);
    }
  }

  @action
  private onProductForEditorIsReady = () => {
    // console.log("product for editor is ready");
    this.productForEditor = this.productForEditorShadow;
  }

  @observable isProcessing = true;

  private initialize = async () => {
    const { productTO } = this;
    const filledValues = productTO.values ? productTO.values.edges.filter(edge => edge.node.simpleValue) : [];

    this.attributeGroupMap = new Map;

    filledValues.forEach(fv => {
      fv && fv.node && fv.node.attribute && fv.node.attribute.attributeGroups.forEach(ag => {
        this.attributeGroupMap.set(ag.stepId, undefined);
      }) || [];
    });

    const attributeGroupArray = Array.from(this.attributeGroupMap.keys());

    const response = await this.batchAttributeGroup(attributeGroupArray).then((response) => {

      Object.keys(response.data).forEach((key) => {
        const attributeGroup: AttributeGroupTO = response.data[key];
        this.attributeGroupMap.set(attributeGroup.stepId, attributeGroup);
      });

      this.attributeGroups = this.mapRawDataToAttributeGroups({ edges: filledValues }, this.attributeGroupMap);

      const nodesWithImage: EdgeWithImage[] = productTO.references
        ? (productTO.references.edges.filter(edge => edge.node.primaryImage !== undefined && edge.node.primaryImage.url) as EdgeWithImage[])
        : [];

      this.productImages = nodesWithImage.map(edge => {
        const { title, url, thumbnail } = edge.node.primaryImage;
        return {
          title,
          url,
          thumbnail
        };
      });

      if (productTO.primaryImage && productTO.primaryImage.url) {
        this.primaryImage = {
          url: productTO.primaryImage.url,
          title: productTO.primaryImage.title,
          thumbnail: productTO.primaryImage.thumbnail,
        };
      }

      const topAttributeGroup = this.attributeGroups.find(group => group.level === 0);

      this.topAttributeGroup = topAttributeGroup ? sort(removeEmptyChildrenAndAttributes(topAttributeGroup)) : undefined;
      runInAction(() => {
        this.isProcessing = false;
      });
    });
  }

  private mapRawDataToAttributeGroups = (values: ValuesTO, attributeGroupsMap: Map<string, AttributeGroupTO | undefined>): AttributeGroup[] => {
    let attrGroups: Map<string, AttributeGroup> = new Map<string, AttributeGroup>();
    values.edges.map(({ node }: { node: ValueEdge }) => {
      const { attribute } = node;

      const fixedAttribute = attribute ? this.showInWorkbench.fixAttributeHierarchy(attribute, attributeGroupsMap) : undefined;

      if (fixedAttribute) {
        fixedAttribute.attributeGroups.forEach((attribGroupTO: AttributeGroupTO) => {
          const thisValueGroup: AttributeGroup | undefined =
            attribGroupTO && attribGroupTO.showInWorkbench ? this.getOrCreateAttributeGroup(attribGroupTO, attrGroups) : undefined;

          if (thisValueGroup) {
            const displaySequence: string | undefined =
              fixedAttribute.displaySequence && fixedAttribute.displaySequence.simpleValue ? fixedAttribute.displaySequence.simpleValue : undefined;

            thisValueGroup.setAttribute(
              fixedAttribute.stepId,
              new Attribute(
                fixedAttribute.stepId,
                fixedAttribute.title,
                node.simpleValue,
                displaySequence,
                fixedAttribute.isConditionallyValid,
                (node as any) as AttributeValueBase
              )
            );
          }
        });
      }
    });

    attrGroups = setChildGroups(attrGroups);

    const attributeGroups: AttributeGroup[] = Array.from(attrGroups.values());
    return attributeGroups;
  };

  private createAttributeGroup = ({
    parent,
    title,
    showInWorkbench,
    displaySequence,
    attributeGroupSection,
    attrValueGroups,
    stepId
  }: CreateAttributeGroupProps) => {
    const attributeGroup = new AttributeGroup({
      stepId,
      title,
      showInWorkbench,
      displaySequence,
      parent: parent ? this.getOrCreateAttributeGroup(parent, attrValueGroups) : undefined
    });

    if (attributeGroupSection) {
      attributeGroup.withSection(attributeGroupSection);
    }

    return attributeGroup;
  };

  private getOrCreateAttributeGroup = (attributeGroupTO: AttributeGroupTO, attrValueGroups: AttrValueGroups): AttributeGroup | undefined => {
    const { displaySequence, ...rest } = attributeGroupTO;

    if (attrValueGroups.has(attributeGroupTO.stepId)) {
      return attrValueGroups.get(attributeGroupTO.stepId);
    } else {
      const attributeGroup = this.createAttributeGroup({
        ...rest,
        attrValueGroups,
        displaySequence: displaySequence && displaySequence.simpleValue ? parseInt(displaySequence.simpleValue, 10) : undefined
      });

      attrValueGroups.set(attributeGroup.stepId, attributeGroup);

      return attributeGroup;
    }
  };
}
