import gql from "graphql-tag";
import { collectionEdges } from "./fragment/attribute.fragment";
import { primaryAndReferencedProductImagesFragment } from "./fragment/image.fragment";
import { getAttributeGroupFieldsFragment } from "../../../ProductEditor/client/queries/fragment/attributeGroup.fragment";
import { SECTION_MEMBERSHIP_ATTRIBUTE_ID } from "../../../../../../graphql/query/types";

export type AttributeGroupTO = {
  stepId: string,
  title: string,
  showInWorkbench: boolean,
  displaySequence: {
    simpleValue: null | number
  },
  attributeGroupSection: {
    simpleValue: null | number,
    valueStepId: string | null,
  }
  parent: null | AttributeGroupTO,
};

export interface BatchQueriesResponse {
  data: {
    [key: string]: AttributeGroupTO
  };
}

export const createBatchQueries = (ids: string[]) => {
  const q = ids.map((id, index) => {
    return `${"a" + index}:attributeGroup(contextStepId: $contextStepId, workspaceStepId: $workspaceStepId, stepId: "${id}") {
      ...AttributeGroupFields
      parent {
        ...AttributeGroupFields
        parent {
          ...AttributeGroupFields
          parent {
            ...AttributeGroupFields
            parent {
              ...AttributeGroupFields
              parent {
                ...AttributeGroupFields
              }
            }
          }
        }
      }
      ...AttributeGroupFields
    }`;
  });

  const query = `query groups($contextStepId: String!, $workspaceStepId: String!) {
    ${q.join(",")}
  }
    ${getAttributeGroupFieldsFragment(SECTION_MEMBERSHIP_ATTRIBUTE_ID)}
  `;

  return gql`${query}`;
};

export const fetchProductNew = () => {
  return gql`
    query product($contextStepId: String!, $workspaceStepId: String!, $nodeType: StepNodeTypes, $stepId: String!) {
      product: stepNode(contextStepId: $contextStepId, workspaceStepId: $workspaceStepId, nodeType: $nodeType, stepId: $stepId) {
        stepId
        title
        ... on Product {
          parent {
            title
          }
          values(excludeEmptyValues: true) {
            ...Edges
          }
        }
        ...Images
      }
    }
    ${collectionEdges}
    ${attributeGroup}
    ${primaryAndReferencedProductImagesFragment}
  `;
};

export const attributeGroup = gql`
  fragment AttributeGroup on AttributeGroup {
    stepId
  }
`;
