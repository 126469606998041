import gql from "graphql-tag";

export const product = gql`
  query product($contextStepId: String!, $workspaceStepId: String!, $nodeType: StepNodeTypes, $stepId: String!) {
    product: stepNode(contextStepId: $contextStepId, workspaceStepId: $workspaceStepId, nodeType: $nodeType, stepId: $stepId) {
      stepId
      title
      ... on Product {
        path
        approvalState
        currentRevision
        lastUpdateDate
        approvalDate
      }
    }
  }
`;
