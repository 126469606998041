import * as React from "react";
import classNames from "classnames";

import { WithStiboStyles, withStiboStyles } from "tim-ui";
import { ElementNames, generateElementId } from "../../../../../common/dataUtils";
import { ValueControlProps, ValueControlReactiveViewPortedWrapper } from "../valueControlWrapper/ValueControlWrapper";
import { NodeUpdateMutation } from "../client/client";
import { ProductAttributesEditorClasses, styles } from "./ProductAttributesEditor.styles";
import { FC, useEffect } from "react";
import ProductTableOfContents from "../productTableOfContents/ProductTableOfContents";
import { IProduct, AttributeGroup, Attribute } from "../../Product/types";
import { IProductEditorStore } from "../productEditorStore";
import { MemberType, ISection, AttributeGroupMember, Members } from "../../Sections/types";
import ToolbarReactiveWrapper from "./ToolbarReactiveWrapper";
import { useSectionRef } from "../ProductEditorModalContent";

const MAX_ATTRIBUTE_LEVEL: number = 10;

export interface ProductAttributesEditorProps {
  id: string;
  sections: ISection[];
  onNodeUpdate: NodeUpdateMutation;
  productEditorStore: IProductEditorStore;
  product: IProduct;
  scrollPos?: number;
  isProductReloading: boolean;
}

export const validMemberFilter = (member: Members) => member.type === MemberType.attributeGroup;

const ProductAttributesEditor: FC<ProductAttributesEditorProps & WithStiboStyles<ProductAttributesEditorClasses>> = props => {
  const container = useSectionRef();
  console.log({ container });
  // const element =  React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (container) {
      container.current.focus();
      container.current.scrollTop = props.scrollPos;
    }
  }, []);

  const renderAttributes = (attributes: Attribute[], classes: Record<ProductAttributesEditorClasses, string>) => {
    return (
      <ul className={classes.attributeList}>
        {attributes.map(attribute => {
          const { stepId, title } = attribute;
          const name = `attribute:${stepId}`;

          const formValue = attribute.attributeValue;

          if (formValue) {
            const valueControlProps: ValueControlProps = {
              name,
              stepId,
              title,
              value: formValue,
              valueControlContainerClassName: classes.valueControlContainer,
              valueControlLabelClassName: classes.valueControlLabel,
              valueControlInputClassName: classes.valueControlInput,
              valueChangeHandler: () => null,
              valueBlurHandler: () => null,
              store: productEditorStore
            };

            const itemId: string = generateElementId(ElementNames.attribute, title);

            return (
              <li id={itemId} key={name} className={classes.attributeItem}>
                <ValueControlReactiveViewPortedWrapper {...valueControlProps} sectionRef={container} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const renderAttributeGroupsHeader = {
    flat: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.firstLevelHeader}>{title}</h4>,
    twoLevels: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.secondLevelHeader}>{title} </h4>,
    threeLevels: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.secondLevelHeader}>{title} </h4>
  };

  const renderAttributeGroups = (attributeGroups: AttributeGroup[], classes: Record<ProductAttributesEditorClasses, string>) => {
    return (
      <ul className={classes.attributeGroupList}>
        {attributeGroups.map((attributeGroup: AttributeGroup, index: number) => {
          const { title, displayType, childGroups, attributes, level } = attributeGroup;
          const itemId: string = generateElementId(ElementNames.attributeGroup, title);
          return (
            <li id={itemId} key={index} className={classes.attributeGroupItem}>
              <div className={classes.attributeGroupContainer}>
                {renderAttributeGroupsHeader[displayType](classes, title)}
                {attributes && attributes.length > 0 && renderAttributes(attributes, classes)}
                {childGroups && childGroups.length > 0 && level <= MAX_ATTRIBUTE_LEVEL && renderAttributeGroups(childGroups, classes)}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderAttributeGroupsMembersHeader = {
    flat: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.firstLevelHeader}>{title}</h4>,
    twoLevels: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.secondLevelHeader}>{title} </h4>,
    threeLevels: (classes: Record<ProductAttributesEditorClasses, string>, title: string) => <h4 className={classes.thirdLevelHeader}>{title} </h4>
  };

  const renderAttributeGroupMembers = (members: Members[], classes: Record<ProductAttributesEditorClasses, string>) => {
    const validMembers = members.filter(member => member.type === MemberType.attributeGroup);

    return (
      <ul className={classes.attributeGroupList}>
        {validMembers &&
          validMembers.map((member: AttributeGroupMember, index: number) => {
            const { title } = member.value;

            const { childGroups, attributes, displayType } = member.value;
            const itemId = generateElementId(ElementNames.attributeGroup, title); // todo this is not generating uniq ids
            return (
              <li id={itemId} key={index} className={classes.attributeGroupItem}>
                {renderAttributeGroupsMembersHeader[displayType](classes, title)}
                {attributes && attributes.length > 0 && renderAttributes(attributes, classes)}
                {childGroups && renderAttributeGroups(childGroups, classes)}
              </li>
            );
          })}
      </ul>
    );
  };

  const renderSections = (sections: ISection[], classes: Record<ProductAttributesEditorClasses, string>) => {
    return (
      <ul className={classes.sectionList}>
        {sections &&
          sections.map(section => {
            const { name, members } = section;
            const itemId = generateElementId(ElementNames.section, name);

            return (
              <li id={itemId} key={name} className={classes.sectionItem}>
                <div className={classes.sectionContainer}>
                  <h3 className={classes.sectionLink}>{name}</h3>
                </div>
                {members && renderAttributeGroupMembers(members, classes)}
              </li>
            );
          })}
      </ul>
    );
  };

  const { sections, classes, productEditorStore, isProductReloading } = props;
  return (
    <div className={classes.background}>
      <ToolbarReactiveWrapper store={productEditorStore} classes={classes} isProductReloading={isProductReloading} />
      <section
        onScroll={productEditorStore.form && productEditorStore.form.onScroll}
        className={classNames(classes.overflowWrapper, classes.wrapper, classes.webkitScrollbar)}
        tabIndex={0}
        ref={container}>
        <ProductTableOfContents sections={sections} members={productEditorStore.members} />
        <section className={classes.container + " intersectionRoot"} >
          {sections && sections.length > 0 ? (
            renderSections(sections, classes)
          ) : productEditorStore.members ? (
            renderAttributeGroupMembers(productEditorStore.members, classes)
          ) : (
                <p className={classes.message}>No product data...</p>
              )}
        </section>
      </section>
    </div>
  );
};

export default withStiboStyles(styles)(ProductAttributesEditor);
