import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SeverityLevel, WithSeverityLevel } from "../../../graphql/query/types";
import SufficiencyIndicator from "../SufficiencyIndicator";
import SufficiencyMessage from "./SufficiencyMessage";
import { SufficiencyResult } from "../../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyPanelSectionClasses =
  | "container"
  | "header"
  | "indicator"
  | "description"
  | "messageSection"
  | "message"
  | "metricSection"
  | "metric"
  | "metricIndicator"
  | "metricSuccess"
  | "metricWarning"
  | "metricError";

const styles = (stiboTheme): StyleRules<SufficiencyPanelSectionClasses> =>
  createStyles({
    container: {
      color: stiboTheme.palette.black[300]
    },
    header: {
      borderBottom: `1px solid ${stiboTheme.palette.black[100]}`,
      paddingBottom: "2px"
    },
    indicator: {
      color: stiboTheme.palette.black[400],
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& span": {
        padding: "3px 16px 3px 16px"
      }
    },
    description: {
      margin: 0,
      marginTop: 20
    },
    messageSection: {
      paddingTop: "20px"
    },
    message: {},
    metricSection: {
      paddingTop: "20px",
      display: "flex",
      flexDirection: "column"
    },
    metric: {
      color: stiboTheme.palette.black[300],
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "4px"
    },
    metricIndicator: {
      color: stiboTheme.palette.black[400],
      padding: "1px 12px 1px 12px"
    },
    metricSuccess: {
      backgroundColor: stiboTheme.palette.success[100]
    },
    metricWarning: {
      backgroundColor: stiboTheme.palette.warning[100]
    },
    metricError: {
      backgroundColor: stiboTheme.palette.danger[100]
    }
  });

interface SufficiencyPanelSectionProps extends StandardProps<React.SelectHTMLAttributes<HTMLElement>, SufficiencyPanelSectionClasses> {
  sufficiency: SufficiencyResult;
}

const SufficiencyPanelSection: React.FC<SufficiencyPanelSectionProps & WithStiboStyles<SufficiencyPanelSectionClasses>> = props => {
  const {
    sufficiency: {
      severityLevel,
      description,
      evaluationDetails: { metricName: title, score, messageHolders, evaluationDetails }
    },
    className: classNameProp,
    classes,
    ...rest
  } = props;

  let messageList: JSX.Element[] = [];
  if (messageHolders) {
    messageHolders.forEach((holder, outerIndex) => {
      holder.messages.forEach((info, innerIndex) => {
        const { severityLevel: messageSeverityLevel, message } = info;

        messageList.push(<SufficiencyMessage key={`message-${outerIndex}-${innerIndex}`} severityLevel={messageSeverityLevel} message={message} />);
      });
    });

    messageList = messageList.sort((a, b) => compareSeverityLevel(a.props, b.props));
  }

  const metricIndicatorClasses = {
    indicator: classes.metricIndicator,
    success: classes.metricSuccess,
    warning: classes.metricWarning,
    error: classes.metricError
  };

  let metricList: JSX.Element[] = [];
  if (evaluationDetails && evaluationDetails.length > 0) {
    evaluationDetails.sort(compareSeverityLevel).forEach(metric => {
      const { metricName: metricTitle, score: metricScore, severityLevel: metricSeverityLevel, showAsBoolean } = metric;

      metricList.push(
        <SufficiencyIndicator
          key={metricTitle}
          className={classes.metric}
          classes={metricIndicatorClasses}
          severityLevel={metricSeverityLevel}
          title={metricTitle}
          score={metricScore}
          showAsBoolean={showAsBoolean}
          data-qa={"sufficiency-panel-metric"}
        />
      );
    });
  }

  const className = classNames(classes.container, classNameProp);

  return (
    <section className={className} data-qa="sufficiency-panel-section" {...rest}>
      <header className={classes.header}>
        <SufficiencyIndicator className={classes.indicator} severityLevel={severityLevel} title={title} score={score} data-qa={"sufficiency-panel-header"} />
      </header>
      {description && (
        <p className={classes.description} data-qa="sufficiency-panel-description">
          {description}
        </p>
      )}
      {messageList.length > 0 && (
        <div className={classes.messageSection} data-qa="sufficiency-panel-messages">
          {messageList}
        </div>
      )}
      {metricList.length > 0 && (
        <div className={classes.metricSection} data-qa="sufficiency-panel-metrics">
          {metricList}
        </div>
      )}
    </section>
  );
};

const compareSeverityLevel = (a: WithSeverityLevel, b: WithSeverityLevel) => {
  if (a.severityLevel === SeverityLevel.Error) return -1;
  if (b.severityLevel === SeverityLevel.Error) return 1;
  if (a.severityLevel === SeverityLevel.Warning) return -1;
  if (b.severityLevel === SeverityLevel.Warning) return 1;
  if (!a) return 1;
  if (!b) return -1;
  return 0;
};

export default withStiboStyles(styles)(SufficiencyPanelSection);
