import { Moment } from "moment";
import moment = require("moment");
import { ApprovalStates } from "../../Product/types";

export interface ApolloResponse {
  loading: boolean;
  networkStatus: number;
}

export interface FetchResult {}

export type GetProduct = (stepId: string) => Promise<GetProductResponse>;

export interface GetProductResponse extends ApolloResponse {
  data: ProductDataTO;
}

export interface ProductDataTO {
  product: ProductTO;
}

export interface ProductTO {
  stepId: string;
  title: string;
  path: string;
  approvalState: ApprovalStates;
  currentRevision: string;
  lastUpdateDate: string;
  approvalDate: string;
}

export class Product {
  stepId: string;
  title: string;
  path: string;
  approvalState: ApprovalStates;
  currentRevision: string;
  lastUpdateDate?: Moment;
  approvalDate?: Moment;

  constructor(productTO: ProductTO) {
    const { stepId, title, path, currentRevision, approvalState } = productTO;
    this.stepId = stepId;
    this.title = title;
    this.path = path;
    this.currentRevision = currentRevision;
    this.approvalState = approvalState;
    this.lastUpdateDate = productTO.lastUpdateDate ? moment(productTO.lastUpdateDate) : undefined;
    this.approvalDate = productTO.approvalDate ? moment(productTO.approvalDate) : undefined;
  }
}

export interface Reason {
  message: string;
}

export interface SufficiencyResponse {
  sufficiencies: {
    sufficiency: {
      allSufficiencies: SufficiencyResult[];
    };
  };
}

export interface Sufficiencies {
  stepId: string;
  sufficiency: {
    allSufficiencies: SufficiencyResult[];
  };
}

export interface SufficiencyResult {
  severityLevel: SeverityLevel;
  description?: string;
  evaluationDetails: EvaluationDetails;
}

export interface EvaluationDetails extends WithSeverityLevel {
  metricId: string;
  metricName: string;
  metricType?: string;
  calculationDate: string;
  score: string;
  showAsBoolean: boolean;
  messageHolders?: MessageHolders[];
  evaluationDetails?: EvaluationDetails[];
}

export interface MessageHolders {
  dataType: {
    stepId: string;
    title: string;
  };
  messages: Message[];
}

export interface Message extends WithSeverityLevel {
  message: string;
}

export interface WithSeverityLevel {
  severityLevel: SeverityLevel;
}

export enum SeverityLevel {
  Success = "SUCCESS",
  Warning = "WARNING",
  Error = "ERROR"
}
