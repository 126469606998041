import { TimGraphApollo } from "tim-bridge";
import { GetProduct } from "./types";
import { product } from "./queries/summary";

export interface IGraphQLClient {
  getProduct: GetProduct;
}

export interface ClientOptions {
  contextID: string;
  workspaceID: string;
}

export class GraphQLClient implements IGraphQLClient {
  client: TimGraphApollo;
  constructor(client: TimGraphApollo, private options: ClientOptions) {
    this.client = client;
  }

  getProduct: GetProduct = (stepId: string) => {
    const { workspaceID, contextID } = this.options;
    return this.client.query({
      query: product,
      variables: {
        contextStepId: contextID,
        workspaceStepId: workspaceID,
        nodeType: "Product",
        stepId
      },
      fetchPolicy: "no-cache"
    });
  };
}
