import { orderBy } from "lodash";

import { AttributeGroupMember } from "./types";
import { SectionStoreBase } from "./SectionStoreBase";
import { AttributeGroup } from "../Product/types";

interface SectionProps {
  attributeGroups: AttributeGroup[];
  name: string;
  order: number;
}

class SectionStore extends SectionStoreBase {
  constructor(props: SectionProps) {
    super();

    const { name, attributeGroups, order } = props;
    const section = attributeGroups;

    const cleanedHierarchy = section ? this.createHierarchy(section, name) : undefined;

    const members = cleanedHierarchy
      ? cleanedHierarchy.map(member => {
        return new AttributeGroupMember(member);
      })
      : [];

    const orderedMembers = orderBy(members, this.orderMembers, ["asc"]);
    this.order = order;
    this.name = name;
    this.members = orderedMembers;
  }

  private conditionToOmitRedundantGroupAlreadyIncludedAsAChildOfOtherGroups = (g: AttributeGroup, name: string): boolean => {
    return (g.parent && g.parent.section.label !== name) || g.parent === null;
  };

  private createHierarchy = (attributeGroups: AttributeGroup[], name: string): AttributeGroup[] => {
    return this.removeNestedWrongSections(attributeGroups, name).filter(group =>
      this.conditionToOmitRedundantGroupAlreadyIncludedAsAChildOfOtherGroups(group, name)
    );
  };

  private removeNestedWrongSections = (attributeGroups: AttributeGroup[], name: string) => {
    return attributeGroups.map(group => {
      const cleaned = group.childGroups && group.childGroups.filter(g => g.section && g.section.label === name);
      return group.withChildGroups(this.removeNestedWrongSections(cleaned, name));
    });
  };
}

export { SectionStore };
