import * as React from "react";
import { WithTranslation } from "tim-bridge";
import { WithStiboStyles, withStiboStyles, StyleRules, createStyles, Tooltip } from "tim-ui";
import { WithBridgeCallbacks, withBridgeCallbacks } from "tim-bridge";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { noSelectableText } from "../../../../../common/classes";
import { nsTranslate } from "../../../../../i18n";

const styles = (stiboTheme): StyleRules<PathBaseClassName> =>
  createStyles({
    container: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "12px",
      lineHeight: "12px",
      color: stiboTheme.palette.black[200],
      cursor: "pointer",
      display: "inline-block",
      ...noSelectableText
    }
  });

export type PathBaseClassName = "container";

export class PathStore {
  transformed: string;
  raw: string;
  private divider = " ▸ ";
  private ancestorsLevel = 3;
  private levelsCounter = 0;

  constructor(path: string) {
    this.transformed = this.addDots(this.replaceSlashes(this.removeLastChild(path)));
    this.raw = path;
  }

  private removeLastChild = (path: string) => {
    const index = path.lastIndexOf("/");
    return path.substring(0, index);
  }

  private replaceSlashes = path => {
    let displayablePath = "";

    const length = path.length;

    for (let i = 0; i < length; i++) {
      if (path[i] === "/") {
        this.levelsCounter++;
        path[i];
        displayablePath = displayablePath + this.divider;
      } else {
        displayablePath = displayablePath + path[i];
      }
    }

    return displayablePath;
  };

  private addDots = path => {
    const amount = this.ancestorsLevel - 1;
    let displayablePath = path;

    if (this.levelsCounter > amount) {
      const l = this.levelsCounter - amount;

      for (let i = 0; i < l; i++) {
        const index = displayablePath.indexOf(this.divider);
        displayablePath = displayablePath.slice(index + 1, displayablePath.length);
      }

      displayablePath = `... ${displayablePath}`;
    }

    return displayablePath;
  };
}

interface PathProps {
  path: string;
}

interface PathInnerProps extends PathProps, WithTranslation, WithStiboStyles<PathBaseClassName>, WithBridgeCallbacks { }

class Path extends React.Component<PathInnerProps> {
  counter = 0;

  onCopied = () => {
    const { t, bridgeCallbacks } = this.props;
    bridgeCallbacks && bridgeCallbacks.showAlert("info", "", t("path.onCopied", { defaultValue: "Path copied to clipboard" }));
  };

  render() {
    const { classes } = this.props as PathProps & WithStiboStyles<PathBaseClassName>;
    const path = new PathStore(this.props.path);

    return (
      <Tooltip title={path.raw} placement="top">
        <CopyToClipboard text={path.raw} onCopy={this.onCopied}>
          <div className={classes.container} id={"ProductSummaryPath"}>
            {path.transformed}
          </div>
        </CopyToClipboard>
      </Tooltip>
    );
  }
}

export default (nsTranslate)(withStiboStyles(styles)(withBridgeCallbacks(Path)));
