import * as React from "react";
import {useEffect, useState} from "react";
import {BridgeCallbacks} from "./App";
import {IGraphQLClient} from "./graphql/client";
import {GetProductResponse, Product, Reason} from "./graphql/types";
import ProductSummary from "./ProductSummary";
import {createStyles, StyleRules, WithStiboStyles, withStiboStyles} from "tim-ui";

export type ProductSummaryClass = "container";

const styles = (stiboTheme): StyleRules<ProductSummaryClass> =>
        createStyles({
            container: {
                clear: "both",
                fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
                fontSize: "14px",
                backgroundColor: "#FFFFFF",
                color: stiboTheme.palette.black[200],
                padding: "0 32px",
                display: "flex",
                justifyContent: "space-between"
            }
        });

interface AppContainerProps {
    graphQLClient: IGraphQLClient;
    productId: string | undefined;
    bridgeCallbacks: BridgeCallbacks;
    hasSufficiencyLicence: boolean;
}

const Container = (props: AppContainerProps & WithStiboStyles<ProductSummaryClass>) => {
            const [product, setProduct] = useState();
            const [isUpdating, setIsUpdating] = useState<boolean>(false);

            useEffect(() => {
                        if (!bridgeCallbacks.showAlert || !bridgeCallbacks.setIsRecalculatingHandler || !bridgeCallbacks.setReloadHandler || !bridgeCallbacks.isBeingRecalculated) {
                            console.log("Missing bridge callbacks for Product Summary. Component won't load.");
                            return;
                        }

                        !!productId && bridgeCallbacks.isBeingRecalculated(productId, (isProductUpdating: boolean) => {
                            setIsUpdating(isProductUpdating);
                        });
                        // TODO: The following checks will most likely revert the effect of this in cases when user navigates away from the product and back before the recalc is done

                        if (!isUpdating) {
                            bridgeCallbacks.setIsRecalculatingHandler(() => {
                                setIsUpdating(true);
                            });
                        }

                        if (isUpdating) {
                            bridgeCallbacks.setReloadHandler(() => {
                                setIsUpdating(false);
                            });
                        }

                        if (!isUpdating) {
                            productId &&
                            graphQLClient
                                    .getProduct(productId)
                                    .then((value: GetProductResponse) => {
                                        if (!value.loading) {
                                            setProduct(new Product(value.data.product));
                                        }
                                    })
                                    .catch((reason: Reason) => {
                                        bridgeCallbacks ? bridgeCallbacks.showAlert("error", "Failed to load product id: " + productId, reason.message) : console.error(reason);
                                    });
                        }
                    }, [isUpdating]
            )
            ;
            const {graphQLClient, productId, bridgeCallbacks, hasSufficiencyLicence, classes} = props;
            console.log("SUMMARY STANDALONE has sufficiency licence: ", hasSufficiencyLicence);
            return (
                    <>
                        {product && (
                                <ProductSummary
                                        title={product.title}
                                        id={product.stepId}
                                        path={product.path}
                                        approvalState={product.approvalState}
                                        currentRevision={product.currentRevision}
                                        approvalDate={product.approvalDate}
                                        lastUpdateDate={product.lastUpdateDate}
                                        className={classes.container}
                                        isUpdating={isUpdating}
                                        hasSufficiencyLicence={hasSufficiencyLicence}
                                />
                        )}
                    </>
            );
        }
;

export const AppContainer = withStiboStyles(styles)(Container);
