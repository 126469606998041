import * as React from "react";
import { Card, CardContent, withStiboStyles, WithStiboStyles, createStyles, Table, TableBody } from "tim-ui";

import { generateTestID } from "../../common/generateTestID";
import { mediaQueries } from "../../common/mediaQueries";
import * as mediaQueryStyles from "./AttributesTableCard.styles";
import { LongMultiValue } from "./ValueContainer/LongMultiValue";
import { ShortMultiValue } from "./ValueContainer/ShortMultiValue";
import { LongSingleValue } from "./ValueContainer/LongSingleValue";
import { ShortSingleValue } from "./ValueContainer/ShortSingleValue";
import { parseMultiValuedAttribute, isMultiValue, isLongValue } from "../../common/valueUtils";
import { ElementNames, generateElementId } from "../../common/dataUtils";
import { Attribute } from "../screen/ProductDetailsScreen/Product/types";

const baseStyles = stiboTheme => {
  const baseCellStyle = {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
    padding: "0px 0px 8px 0px",
    fontSize: "14px",
    verticalAlign: "text-top"
  };

  return createStyles({
    card: {
      boxShadow: "none",
      backgroundColor: "inherit"
    },
    content: {
      marginTop: stiboTheme.layout.gridPoint,
      padding: "0px",
      "&:last-child": {
        paddingBottom: "0px"
      }
    },
    table: {
      marginTop: stiboTheme.layout.gridPoint * 2
    },
    tableRow: {
      height: stiboTheme.layout.gridPoint * 3,
      "&:last-child": {
        paddingBottom: stiboTheme.layout.gridPoint * 3
      },
      lineHeight: "24px",
      "& div[data-qa='read-only-field']": {
        padding: 0,
        color: "inherit",
        fontSize: "inherit",
        fontFamily: "inherit",
        "& span": {
          background: "inherit"
        }
      }
    },
    tableRowMultiLinesKey: {
      "& td": {
        paddingTop: "24px"
      },
      lineHeight: "24px"
    },
    tableRowMultiLinesValue: {
      "& td:last-of-type": {
        paddingBottom: "24px"
      },
      lineHeight: "24px"
    },
    tableCellLeftAligned: {
      ...baseCellStyle,
      "&:first-child": {
        paddingRight: "8px",
        width: "40%",
        minWidth: "80px",
        wordBreak: "break-word",
        color: stiboTheme.palette.black[300]
      },
      [mediaQueries.smallMobilePortrait]: mediaQueryStyles.attributeLabelWidthFor4Col,
      [mediaQueries.smallMobileLandscape]: mediaQueryStyles.attributeLabelWidthFor4Col,
      [mediaQueries.largeMobilePortrait]: mediaQueryStyles.attributeLabelWidthFor4Col,
      [mediaQueries.largeMobileLandscape]: mediaQueryStyles.attributeLabelWidthFor8Col,
      [mediaQueries.smallIPhonesLandscape]: mediaQueryStyles.attributeLabelWidthFor8Col,
      [mediaQueries.smallIPhonesPortrait]: mediaQueryStyles.attributeLabelWidthFor4Col,
      [mediaQueries.iPadLandscape]: mediaQueryStyles.attributeLabelWidthFor8Col,
      [mediaQueries.iPadPortrait]: mediaQueryStyles.attributeLabelWidthFor4Col,
      [mediaQueries.iPhonesLandscape]: mediaQueryStyles.attributeLabelWidthFor8Col,
      [mediaQueries.iPhonesPortrait]: mediaQueryStyles.attributeLabelWidthFor4Col
    },
    tableCellTopAligned: {
      ...baseCellStyle
    },
    tableCellTopAlignedLabel: {
      ...baseCellStyle,
      wordBreak: "break-word",
      color: stiboTheme.palette.black[300]
    }
  });
};

export interface Props {
  title?: string;
  titleStyle: string;
  attributes: Attribute[];
}

const AttributesTableCard: React.SFC<WithStiboStyles<typeof baseStyles> & Props> = props => {
  const { classes, title, titleStyle, attributes } = props;

  const elementTitle: string = title ? title : "";

  const elementId: string = generateElementId(ElementNames.attributeGroup, elementTitle);

  return (
    <div id={elementId}>
      <Card className={classes.card} id={generateTestID(`Card_${title}`)}>
        <CardContent className={classes.content} id={generateTestID(`CardContent_${title}`)}>
          {title && (
            <div className={titleStyle} id={generateTestID(`CardContentLabel_${title}`)}>
              {title}
            </div>
          )}
          <Table className={classes.table} dividers="none">
            <TableBody>
              {attributes.map((attribute, index) => {
                const label: string = attribute.title;
                const value: string = parseMultiValuedAttribute(attribute.value);

                if (attribute.isConditionallyValid === false) {
                  return null;
                }

                if (isMultiValue(value)) {
                  if (isLongValue(value)) {
                    return <LongMultiValue key={index} id={index} value={value} label={label} classes={classes} />;
                  } else {
                    return <ShortMultiValue key={index} id={index} value={value} label={label} classes={classes} />;
                  }
                } else {
                  if (isLongValue(value)) {
                    return <LongSingleValue key={index} id={index} value={value} label={label} classes={classes} />;
                  } else {
                    return <ShortSingleValue key={index} id={index} value={value} label={label} classes={classes} />;
                  }
                }
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStiboStyles(baseStyles)(AttributesTableCard);
