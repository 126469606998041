import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SeverityLevel } from "../../../graphql/query/types";

export type SufficiencyMessageClasses = "container" | "indicator" | "success" | "warning" | "error";

const styles = (stiboTheme): StyleRules<SufficiencyMessageClasses> =>
  createStyles({
    container: {
      display: "flex",
      color: stiboTheme.palette.black[400],
      lineHeight: "14px",
      marginBottom: "8px"
    },
    indicator: {
      minWidth: "8px",
      width: "8px",
      minHeight: "8px",
      height: "8px",
      borderRadius: "2px",
      margin: "3px 16px 0 16px"
    },
    success: {
      backgroundColor: stiboTheme.palette.success[500]
    },
    warning: {
      backgroundColor: stiboTheme.palette.warning[900]
    },
    error: {
      backgroundColor: stiboTheme.palette.danger[500]
    }
  });

interface SufficiencyMessageProps extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyMessageClasses> {
  severityLevel?: SeverityLevel;
  message: string;
}

const SufficiencyMessage: React.FC<SufficiencyMessageProps & WithStiboStyles<SufficiencyMessageClasses>> = props => {
  const { severityLevel, message, className: classNameProp, classes, ...rest } = props;

  const className = classNames(classes.container, classNameProp);
  const configuration = classNames(
    classes.indicator,
    {
      [classes.success]: severityLevel === SeverityLevel.Success
    },
    {
      [classes.warning]: severityLevel === SeverityLevel.Warning
    },
    {
      [classes.error]: severityLevel === SeverityLevel.Error
    }
  );

  return (
    <span className={className} data-qa="sufficiency-message" {...rest}>
      <div className={configuration} />
      <div>{message}</div>
    </span>
  );
};

export default withStiboStyles(styles)(SufficiencyMessage);
