import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { useTranslation } from "tim-bridge";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SeverityLevel } from "../../graphql/query/types";

export type SufficiencyIndicatorClasses = "container" | "indicator" | "success" | "warning" | "error";

const styles = (stiboTheme): StyleRules<SufficiencyIndicatorClasses> =>
  createStyles({
    container: {
      display: "inline-block",
      color: stiboTheme.palette.black[200],
      whiteSpace: "nowrap"
    },
    indicator: {
      color: stiboTheme.colors.white,
      padding: "2px 7px 2px 7px",
      borderRadius: "2px"
    },
    success: {
      backgroundColor: stiboTheme.palette.success[500]
    },
    warning: {
      backgroundColor: stiboTheme.palette.warning[700]
    },
    error: {
      backgroundColor: stiboTheme.palette.danger[500]
    }
  });

interface SufficiencyIndicatorProps extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyIndicatorClasses> {
  severityLevel: SeverityLevel;
  title: string;
  score: string;
  showAsBoolean?: boolean;
  "data-qa"?: string;
}

const SufficiencyIndicator: React.FC<SufficiencyIndicatorProps & WithStiboStyles<SufficiencyIndicatorClasses>> = props => {
  const { severityLevel, title, score, showAsBoolean, className: classNameProp, classes, "data-qa": dataQa, ...rest } = props;

  const { t } = useTranslation();

  const className = classNames(classes.container, classNameProp);
  const configuration = classNames(
    classes.indicator,
    {
      [classes.success]: severityLevel === SeverityLevel.Success
    },
    {
      [classes.warning]: severityLevel === SeverityLevel.Warning
    },
    {
      [classes.error]: severityLevel === SeverityLevel.Error
    }
  );

  let sufficiency = score;
  if (showAsBoolean) {
    switch (severityLevel) {
      case SeverityLevel.Success:
        sufficiency = `${t("sufficiencyIndicator.success", {
          defaultValue: "Success"
        })}`;
        break;
      case SeverityLevel.Warning:
        sufficiency = `${t("sufficiencyIndicator.warning", {
          defaultValue: "Warning"
        })}`;
        break;
      case SeverityLevel.Error:
        sufficiency = `${t("sufficiencyIndicator.error", {
          defaultValue: "Error"
        })}`;
        break;
    }
  }

  return (
    <span className={className} data-qa={dataQa || "sufficiency-indicator"} {...rest}>
      {title} <span className={configuration}>{sufficiency}</span>
    </span>
  );
};

export default withStiboStyles(styles)(SufficiencyIndicator);
