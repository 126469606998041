import { ISection, Members, AttributeGroupMember } from "./types";

class SectionStoreBase implements ISection {
  name: string;
  order: number;
  members: Array<Members>;

  orderMembers = (member: AttributeGroupMember) => {
    return member.value.displaySequence;
  };

  addMemberAsAFirstOne = (member: Members) => {
    this.members = [member, ...this.members];
  };
}

export { SectionStoreBase };
