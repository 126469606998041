import { StandardProps } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "tim-bridge";
import { Close, createStyles, IconButton, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import SufficiencyPanelSection from "./SufficiencyPanelSection";
import { mediaQueries } from "../../../common/mediaQueries";
import { SufficiencyResult } from "../../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyPanelClasses = "container" | "hidden" | "close" | "content" | "section" | "title";

const styles = (stiboTheme): StyleRules<SufficiencyPanelClasses> =>
  createStyles({
    container: {
      backgroundColor: stiboTheme.palette.grey[100],
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2)",
      height: "100vh",
      minWidth: "320px",
      position: "fixed",
      top: 0,
      right: 0,
      transition: "transform .5s ease-out",
      transform: "translateX(0vw)",
      overflowY: "auto",
      outline: "none",
      zIndex: 1000,
      [mediaQueries.columns4]: {
        width: "25vw"
      },
      [mediaQueries.columns3]: {
        width: "33vw"
      },
      [mediaQueries.columns2]: {
        width: "50vw"
      },
      [mediaQueries.columns1]: {
        width: "100vw"
      }
    },
    hidden: {
      [mediaQueries.columns4]: {
        transform: "translateX(50vw)"
      },
      [mediaQueries.columns3]: {
        transform: "translateX(66vw)"
      },
      [mediaQueries.columns2]: {
        transform: "translateX(75vw)"
      },
      [mediaQueries.columns1]: {
        transform: "translateX(100vw)"
      }
    },
    close: {
      position: "fixed",
      top: "24px",
      right: "24px",
      width: "32px",
      height: "32px"
    },
    content: {
      padding: "40px 24px 24px 40px"
    },
    section: {
      marginBottom: "28px"
    },
    title: {
      margin: "0 0 12px 0",
      fontSize: "20px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[300]
    }
  });

interface SufficiencyPanelProps extends StandardProps<React.SelectHTMLAttributes<HTMLElement>, SufficiencyPanelClasses> {
  sufficiencies: SufficiencyResult[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const SufficiencyPanel: React.FC<SufficiencyPanelProps & WithStiboStyles<SufficiencyPanelClasses>> = props => {
  const { sufficiencies, visible, setVisible, className: classNameProp, classes } = props;

  if (sufficiencies.length === 0) {
    return null;
  }

  const { t } = useTranslation();
  const [focused, setFocused] = useState<boolean>(false);

  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (panelRef.current && visible) {
      panelRef.current.focus();
      setFocused(true);
    }
  }, [visible]);

  const sufficiencySections: JSX.Element[] = [];
  sufficiencies.forEach(sufficiency => {
    const {
      evaluationDetails: { metricName: title }
    } = sufficiency;

    sufficiencySections.push(<SufficiencyPanelSection key={title} sufficiency={sufficiency} className={classes.section} />);
  });

  const handleBlur = (e: React.FocusEvent) => {
    const active = panelRef && document.activeElement === panelRef.current;
    if (focused && !active) {
      setFocused(false);
      setVisible(false);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (panelRef.current && visible) {
      panelRef.current.blur();
    }
  };

  const className = classNames(classes.container, { [classes.hidden]: !visible }, classNameProp);

  return (
    <div className={className} ref={panelRef} tabIndex={0} onBlur={handleBlur} data-qa="sufficiency-panel">
      <IconButton className={classes.close} onClick={handleClick} aria-label="Close">
        <Close />
      </IconButton>
      <div className={classes.content} data-qa="sufficiency-panel-content">
        <h1 className={classes.title} data-qa="sufficiency-panel-title">{`${t("sufficiencyPanel.title", {
          defaultValue: "Quality and Sufficiency"
        })}`}</h1>
        {sufficiencySections.map(section => {
          return section;
        })}
      </div>
    </div>
  );
};

export default withStiboStyles(styles)(SufficiencyPanel);
