import { flatten } from "lodash";
import { Field } from "@stibo/value-components/form/formFieldsReducer";
import { AttributeSingleValue, AttributeMultiValue, ValidationMessage } from "@stibo/value-components";
import { observable, computed, action } from "mobx";
import { IProduct } from "../Product/types";
import { ValidationResult } from "./productEditorStore";
import { computedAsync } from "computed-async-mobx";
import { IEditClient } from "./client/client";

export type AttributeValueBase = AttributeSingleValue | AttributeMultiValue;

type ExtendedField = Field<AttributeValueBase> & { messages?: any };

export function convertValueToValueInput(value: AttributeValueBase) {
  return isAttributeMultiValue(value)
    ? value.values!.map(sv => ({
      value: typeof sv.value === "undefined" ? null : sv.value,
      valueStepId: sv.valueStepId,
      unitStepId: sv.unit && sv.unit.stepId
    }))
    : {
      value: typeof value.value === "undefined" ? null : value.value,
      valueStepId: value.valueStepId,
      unitStepId: value.unit && value.unit.stepId
    };
}

function isAttributeMultiValue(value: AttributeValueBase): value is AttributeMultiValue {
  return value.attribute.isMultiValued!;
}

// todo move isDirty here
class FormField {
  name: string;
  title: string;
  readOnly: boolean;
  mandatory: boolean;
  height: number = 40;
  value: AttributeSingleValue | AttributeMultiValue;
  @observable updateCounter = 0; // it't better to use this simple field to force input reload (previously used value type is to complex and cause convertion of all internal props to observables )
  @observable messages;
  constructor(public rawValue: AttributeSingleValue | AttributeMultiValue, private client: IEditClient) {
    this.name = `attribute:${rawValue.attribute.stepId}`;
    this.title = rawValue.attribute.title;
    this.value = normalizeAttributeValue(rawValue);

    (this.readOnly = false), (this.mandatory = false), (this.messages = []);
  }
  fetchedLOVs = computedAsync(undefined, () => {
    if (this.rawValue.attribute.hasListOfValues) {
      return this.client.fetchListOfValues(this.rawValue.attribute.stepId);
    }
    return undefined;
  });

  @computed get LOVs() {
    const { value } = this.fetchedLOVs;
    return value;
  }
}

function normalizeAttributeValue(value: AttributeValueBase): AttributeValueBase {
  if (isAttributeMultiValue(value)) {
    return {
      ...value,
      values: value.values.map(singleValue => ({
        ...singleValue,
        value: singleValue.value === null ? "" : singleValue.value
      }))
    };
  } else {
    return { ...value, value: value.value === null ? "" : value.value };
  }
}

export class Form {
  @observable fields: FormField[];
  originalFieldsValues = {};
  @observable dirtyFields: string[] = [];
  @observable invalidFields: string[] = [];

  constructor(private client, private onFormDirty: (val: boolean) => void, product: IProduct) {
    this.init(product);
    this.revision = product.updateCounter;
    setTimeout(() => {
      this.handlersEnabled = true;
    }, 300);
  }

  revision: number;

  // needed to disable some expensive handlers in value components
  isScrollingTimer;
  handlersEnabled = false;
  onScroll = () => {
    clearTimeout(this.isScrollingTimer);
    this.handlersEnabled = false;

    this.isScrollingTimer = setTimeout(() => {
      this.handlersEnabled = true;
    }, 500);
  }

  init = (product: IProduct) => {
    this.fields = (product ? this.getAllAttributeValues(product) : []).map((value: AttributeSingleValue | AttributeMultiValue) => {
      return new FormField(value, this.client);
    });

    for (const field of this.fields) {
      this.originalFieldsValues[field.name] = field.value;
    }
  }

  checkFieldIsDirty = field => {
    if (field) {
      const originalField = this.originalFieldsValues[field.name];

      const fieldValue = field.value as any;
      if (originalField.attribute.isMultiValued) {
        const valuesLength = originalField.values.length;
        if (valuesLength === 0) {
          return fieldValue.values.length > 1 ? true : fieldValue.values[0].value !== "";
        }
        return fieldValue.values.some((v, index) => {
          return valuesLength === fieldValue.values.length ? v.value !== originalField.values[index].value : true;
        });

      } else {
        return fieldValue.value !== originalField.value || fieldValue.unit !== originalField.unit;
      }


    }
    return false;
  };

  getAllAttributeValues(product: IProduct): Array<AttributeSingleValue | AttributeMultiValue> {
    return flatten(
      product.attributeGroups.map(attributeGroup => {
        return attributeGroup.attributes.map(ag => ag.attributeValue).filter(a => a);
      })
    );
  }

  @computed get isDirty() {
    return this.dirtyFields.length > 0;
  }

  @computed get isValid() {
    return this.invalidFields.length === 0;
  }

  @computed get canSave() {
    return this.isDirty && this.isValid;
  }

  private debounceTimeouts = {}; // typescript issue with type
  counter = 0;
  @action
  onUpdateValue = (field: FormField, value) => {
    if (this.handlersEnabled) {
      field.value = value;
      field.updateCounter = ++this.counter;
      this.onFormDirty(true);

      clearTimeout(this.debounceTimeouts[field.value.attribute.stepId]);
      this.debounceTimeouts[field.value.attribute.stepId] = setTimeout(() => {
        this.onValidate(field);
        this.onCheckIsDirty(field);
      }, 500);
    }
  };

  @action
  onControlBlur = (field: Field<AttributeSingleValue | AttributeMultiValue>, value) => {
    // if (this.handlersEnabled) {
    //   field.value = value;

    //   clearTimeout(this.debounceTimeouts[field.value.attribute.stepId]);
    //   this.onCheckIsDirty(field);
    //   this.onValidate(field);
    // }
  };

  @action
  onCheckIsDirty = (field: Field<AttributeSingleValue | AttributeMultiValue>) => {
    if (this.checkFieldIsDirty(field)) {
      this.dirtyFields = this.dirtyFields.filter(f => f !== field.name).concat([field.name]);
    } else {
      this.dirtyFields = this.dirtyFields.filter(f => f !== field.name);
    }
  };

  @action
  onValidate = (field: Field<AttributeSingleValue | AttributeMultiValue>) => {
    this.client
      .validate(field.value)
      .then(result => {
        this.onValidateSuccess(field, result.data.validateValue);
      })
      .catch(reason => {
        return [{ message: reason, severity: "error" }] as Array<ValidationResult>;
      });
  };

  @action
  onValidateSuccess = (field: ExtendedField, validateValue: ValidationMessage[]) => {

    field.messages = validateValue;

    if (validateValue.some(v => v.severity === "error")) {
      this.invalidFields = this.invalidFields.filter(name => name !== field.name).concat([field.name]);
    } else {
      this.invalidFields = this.invalidFields.filter(name => name !== field.name);
    }
  };

  @action
  onSave = (updatedAttributes: AttributeValueBase[]) => {
    const updatedFormFields: FormField[] = updatedAttributes.map(value => new FormField(value, this.client));
    for (const field of updatedFormFields) {
      this.originalFieldsValues[field.name] = field.value;
      this.onCheckIsDirty(field);
    }
    this.onFormDirty(false);
  };

  getDirtyFieldsValuesToSave = () => {
    return this.dirtyFields
      .filter(name => name.indexOf("attribute:") === 0)
      .map(fieldName => {
        const field = this.fields.find(field => field.name === fieldName);

        if (field) {
          return this.convertAttributeValueToValueInput(field.value);
        } else {
          return undefined;
        }
      })
      .filter(field => field);
  };

  private convertAttributeValueToValueInput(value: AttributeValueBase) {
    return {
      attributeStepId: value.attribute.stepId,
      value: convertValueToValueInput(value)
    };
  }
}
