import * as React from "react";
import { WithStiboStyles, withStiboStyles, StyleRules, createStyles } from "tim-ui";

const styles = (stiboTheme): StyleRules<TitleBaseClassName> =>
  createStyles({
    container: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 600,
      color: stiboTheme.palette.black[400],
      marginTop: "8px",
      wordBreak: "break-all"
    }
  });

interface TitleProps {
  children;
}

const Title = (props: TitleProps & WithStiboStyles<TitleBaseClassName>) => {
  const { classes, children } = props;
  return (
    <header className={classes.container} id={"ProductSummaryHeader"}>
      {children}
    </header>
  );
};

export type TitleBaseClassName = "container";

export default withStiboStyles(styles)(Title);
