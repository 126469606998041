import * as React from "react";
import { TableRow, TableCell, createStyles, WithStiboStyles, withStiboStyles } from "tim-ui";
import { generateTestID } from "../../../common/generateTestID";
import { ValueBaseProps } from "./ValueBase";

const styles = () =>
  createStyles({
    tableRow: {},
    tableCellLeftAligned: {
      wordBreak: "break-word",
      wordWrap: "break-word",
      "& ul": { paddingLeft: "21px" }, // need to override webui all selector
      paddingLeft: "0!important",
      paddingRight: "0!important"
    }
  });

const ShortSingleValueBase: React.SFC<ValueBaseProps & WithStiboStyles<typeof styles>> = props => {
  const { id, value, label, classes } = props;
  return (

    <TableRow className={classes.tableRow} key={id} id={generateTestID(`TableRow_${value}_${id}`)}>
      <TableCell className={classes.tableCellLeftAligned} id={generateTestID(`TableCellKeyLabel_${label}`)}>
        {label}
      </TableCell>

      <TableCell className={classes.tableCellLeftAligned} id={generateTestID(`TableCellValue_${label}`)}>
        {value}
      </TableCell>
    </TableRow>
  );
};

const ShortSingleValue = withStiboStyles(styles)(ShortSingleValueBase);

export { ShortSingleValue, ShortSingleValueBase };
