import * as React from "react";
import { Component } from "react";
import { createStiboTheme, defaultStiboTheme, ThemeProvider } from "tim-ui";
import { hot } from "react-hot-loader";
import {
  withComponentParams,
  withSelection,
  WithSelection,
  withGraphClient,
  WithTimGraphClientInstance,
  withGraphqlEndpoint,
  TimGraphApollo,
  withTranslations,
  WithComponentParams,
  TimGraphClient
} from "tim-bridge";
import ProductDetailsScreen from "../screen/ProductDetailsScreen/ProductDetailsScreen";
import { StiboThemeProvider } from "@stibo/value-components";
import { WithApolloClient } from "react-apollo";
import { ProductViewerStore } from "../screen/ProductDetailsScreen/ProductViewer/ProductViewerStore";
import { ViewerClient } from "../screen/ProductDetailsScreen/ProductViewer/client/client";
import { EditClient } from "../screen/ProductDetailsScreen/ProductEditor/client/client";

const colors = {
  ...defaultStiboTheme.colors,
  accent: "#003daf",
  danger: "#FC4C02",
  info: "#0032A0",
  navigation: "#0071B4",
  success: "#46AF50",
  warning: "#FFD200"
};

export const stiboTheme = createStiboTheme(colors);

class AppMain extends Component<
  WithSelection &
  WithComponentParams<{ hasSufficiencyLicence: boolean, isSyndigoAvailable: boolean, isEditable: boolean }> &
  Partial<{
    contextId: string;
    workspaceId: string;
    isEditable: boolean;
  }> &
  WithApolloClient<{ graphClient: TimGraphClient }> &
  WithApolloClient<{ client: TimGraphApollo }>
  > {
  render() {
    const { client } = this.props;
    const hasSufficiencyLicence =
      this.props.componentParams && this.props.componentParams.hasSufficiencyLicence ? this.props.componentParams.hasSufficiencyLicence : false;
    const isSyndigoAvailable =
      this.props.componentParams && this.props.componentParams.isSyndigoAvailable ? this.props.componentParams.isSyndigoAvailable : false;
    const isEditable =
      this.props.componentParams && this.props.componentParams.isEditable ? this.props.componentParams.isEditable || false : false;

    const { stepId, __typename: nodeType } = this.props.selection;
    const { workspaceId: workspaceID = "", contextId: contextID = "" } = this.props;
    const config = {
      stepId,
      nodeType,
      workspaceID,
      contextID,
      hasSufficiencyLicence,
      isSyndigoAvailable
    };

    const viewerClient = new ViewerClient({
      graphqlClient: this.props.graphClient.getDefaultClient(),
      config
    });

    const editClient = new EditClient({
      graphqlClient: this.props.graphClient.getDefaultClient(),
      sufficiencyClient: client,
      config
    });

    return (
      (document.body.style.margin = "0px"),
      (
        <ThemeProvider stiboTheme={stiboTheme}>
          <StiboThemeProvider>
            <ProductDetailsScreen
              config={config}
              productViewerStore={
                new ProductViewerStore({
                  client: viewerClient,
                  //   businessObjectsClient: undefined, // todo this feature is disabled
                  editorClient: editClient,
                  isEditable,
                })
              }
              editClient={editClient}
            />
          </StiboThemeProvider>
        </ThemeProvider>
      )
    );
  }
}

const AppWithTranslation = withTranslations(AppMain);
const AppWithSelection = withSelection(AppWithTranslation); // TODO probably can be removed
const AppWithGraphQlClient = withGraphClient()(AppWithSelection);
const AppWithReferenceObjectClient = withGraphqlEndpoint("sufficiency-graphql")(AppWithGraphQlClient);
const AppWithComponentParams = withComponentParams(AppWithReferenceObjectClient);
const AppWithHotReload = hot(module)(AppWithComponentParams);

export default AppWithHotReload;

export { AppWithComponentParams, AppWithHotReload };
